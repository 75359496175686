import { graphql, PageProps } from "gatsby";
import React from "react";
import { CardDeck } from "../components/Card/Card";
import Layout, { MainContainerDiv, PageTitle } from "../components/Layout";
import { Release } from "../components/Release";
import { Music } from "./__generated__/Music";

export default (props: PageProps<Music>) => {
	const releases = props.data.allMarkdownRemark.nodes.map((node) => node.frontmatter);

	return (
		<Layout title="Music">
			<MainContainerDiv>
				<PageTitle>Music</PageTitle>
				<CardDeck>
					{releases.map((release) => <Release release={release!} key={release!.name!} />)}
				</CardDeck>
			</MainContainerDiv>
		</Layout>
	);
};

export const pageQuery = graphql`
	query Music {
		allMarkdownRemark(
			filter: {fileAbsolutePath: {regex: "/data\\/releases\/.*md/"}}
			sort: {fields: frontmatter___release_date, order: DESC}
		) {
			nodes {
				frontmatter {
					formattedDate: release_date(formatString: "dddd, Do MMMM YYYY")
					type
					name
					image
					link
				}
			}
		}
	}
`;